"use client";

interface AddError {
  /**
   * @deprecated
   * use the two-argument version instead
   */
  (err: DisplayError): void;
  (summary: string, full: string): void;
};

import { createContext, useContext } from "react";
export type DisplayError = {
  summary: string;
  full: string;
}
export type IErrorContext = {
  errors: (DisplayError & {id: string})[];
  addError: AddError;
  closeError: (index: number) => void;
};
export const ErrorContext = createContext<IErrorContext>({
  errors: [],
  addError: () => {},
  closeError: () => {},
});

export const useError = () => {
  const {errors, addError, closeError} = useContext(ErrorContext);

  return {
    errors,
    addError,
    closeError,
  }
};
