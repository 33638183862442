"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";
import { useRef, useState } from "react";
import { Modal } from "../../core/modal";
import "./styles.scss";
const LandingVideo = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const youtubeUrl = "https://www.youtube.com/watch?v=l6vuBOCUI6E";
  const videoMp4Url = "/SellRazeTrailer.mp4";
  const videoWebmUrl = "/SellRazeTrailer.webm";
  const videoRef = useRef<HTMLVideoElement>(null);
  const router = useRouter();

  let mobile = false;
  if (typeof window !== "undefined" && window.innerWidth < 768) {
    mobile = true;
  }
  return (
    <div className="mt-5 gap-1 items-start">
      <div
        className="align-center landing-video mt-3 cursor-pointer  gap-[6px]"
        onClick={() => {
          mobile ? router.push(youtubeUrl) : setVideoModalOpen(true);
        }}
      >
        <p className="bg-clip-text text-transparent bg-blue-gradient-dark cursor-pointer  font-sans">Watch trailer</p>
        <Image
          className="[ video_play ] cursor-pointer"
          src="/playbutton.svg"
          width="24"
          height="24"
          alt=""
          onClick={() => {
            mobile ? router.push(youtubeUrl) : setVideoModalOpen(true);
          }}
          priority
        />
      </div>

      {/* <img loading="lazy" src="playbutton.svg" className="w-6 aspect-[1.04]" /> */}
      <Modal
        open={videoModalOpen}
        onClose={() => {
          setVideoModalOpen(false);
          if (videoRef.current) {
            videoRef.current.pause();
          }
        }}
        className="video_modal rounded-2xl lg:max-w-[50%]"
      >
        <div className="popup__overlay [ dark-blurred ]" onClick={() => setVideoModalOpen(false)}></div>
        <div className="[ center-child video-div ]">
          <video controls height="600rem" ref={videoRef} className="flex ">
            <source src={videoMp4Url} type="video/mp4" />
            <source src={videoWebmUrl} type="video/webm" />
            video could not be loaded
          </video>
        </div>
      </Modal>
    </div>
  );
};

export default LandingVideo;
