import Link from 'next/link'
import Image from 'next/image'

const AppAndPlayStore = () => {

    return (
        <div className="flex flex-row items-center justify-start mt-3">
            <Link
                href="https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085?itsct=apps_box_badge&amp;itscg=30200"
                passHref
            >
                <Image
                    src="./app_store.svg"
                    alt="Download on the App Store"
                    width={200}
                    height={75}
                  
                />
            </Link>
            <Link
                href="https://play.google.com/store/apps/details?id=com.sellraze.scanner&pcampaignid=web_share"
                passHref
            >
                <Image
                    src="/play_store.png"
                    alt="Get it on Google Play"
                    width={255}
                    height={126}
                    style={{ borderRadius: "13px" }}
                />
            </Link>
        </div>
    )
}

export default AppAndPlayStore