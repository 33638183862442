export const AffiliateIcon = ({
  icon,
  width,
  height,
  alt,
    className,
  style,
}: {
  icon: string | null;
  width: string | number;
  height: string | number;
  alt?: string;
        className?: string;
  style?: React.CSSProperties;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width}
    height={height}
  >
    {alt ? <title>{alt}</title> : null}
    <use href={`/affiliate_icons.svg#${icon}`} />
  </svg>
);

export const AffiliateIconPng = ({
  icon,
  width,
  height,
  alt,
  className,
  style,
}: {
  icon: string | null;
  width: string | number;
  height: string | number;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const imgStyle = {
    transform: `scale(${AffiliateZoomMap[icon ? icon : "None"]}) translate(${AffiliateTranslateXMap[icon ? icon : "None"] || 0}px, ${
      AffiliateTranslateYMap[icon ? icon : "None"] || 0
    }px)`,
  };

  return (
    <img
      className={className}
      src={`/affiliate_icons/${icon}.png`}
      width={width}
      height={height}
      alt={alt}
      style={{ ...style, ...imgStyle }}
    />
  );
};

export enum AffiliateName {
  Affiliate1 = "Polar Chefs",
  Affiliate2 = "AmzHub",
  Affiliate3 = "Frozen Freebies",
  Affiliate4 = "JIGIO Notify",
  Affiliate5 = "Hayha Bots",
  Affiliate6 = "ZNA Kicks",
  Affiliate7 = "Sauce Servers",
  Affiliate8 = "Megoda",
  Affiliate9 = "Chilly's Igloo ACO",
  Affiliate10 = "Facebook",
  Affiliate11 = "Spikewmu",
}

export enum AffiliateImageBackgroundColor {
  Background1 = "black",
  Background2 = "white",
} 

const AffiliateZoomMap: { [key: string]: number } = {
  "Polar Chefs": 0.7,
  "AmzHub": 1.1,
  "Frozen Freebies": 1,
  "JIGIO Notify": 0.8,
  "ZNA Kicks": 0.8,
  "Sauce Servers": 0.7,
  "Megoda": 0.7,
  "None": 1,
  "Chilly's Igloo ACO": 0.9,
  "Spikewmu": 0.55,
}

const AffiliateTranslateXMap: { [key: string]: number } = {
  "AmzHub": 3,
  "Sauce Servers": -2,
  "Megoda": -1,
  "None": 0,
  "Chilly's Igloo ACO": 0,
  "Spikewmu": 4,
}

const AffiliateTranslateYMap: { [key: string]: number } = {
  "Polar Chefs": 1,
  "AmzHub": -1,
  "Frozen Freebies": 0,
  "JIGIO Notify": -3,
  "ZNA Kicks": -2,
  "Spikewmu": -5,
  "None": 0,
}