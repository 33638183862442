import { CSSProperties, ReactNode, useEffect, useRef } from "react";

interface ModalProps {
  className?: string;
  style?: CSSProperties;
  open: boolean;
  children: ReactNode | ReactNode[];
  modal?: boolean;
  onClose: () => void;
}

export const Modal = ({
  className,
  style,
  open,
  children,
  modal = true,
  onClose,
}: ModalProps) => {
  const ref = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (open && ref.current?.open === false) {
      if (modal) {
        ref.current?.showModal?.();
      } else {
        ref.current?.show?.();
      }
    } else {
      ref.current?.close?.();
    }
  }, [open, modal]);
  return (
    <dialog onCancel={onClose} onClose={onClose} className={className} style={style} ref={ref}>
      {children}
    </dialog>
  );
};
