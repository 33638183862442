import Image from "next/image";

interface HeaderProps {
    text: string;
    className?: string;
    arrow?: boolean;
}


export const AboutHeader: React.FC<HeaderProps> = ({ text, className, arrow = false }) => {
    return (
        <div className={"mx-auto inline-flex" + className}>
            <div
                // className="flex gap-1 items-start pr-9 py-1.5 text-xs font-medium tracking-normal text-left rounded-3xl border-2 border-solid border-gray-200 hover:border-true-blue bg-neutral-100   text-zinc-950 max-md:flex-wrap max-md:px-5 max-md:max-w-full max-w-[400px]"
                className=" inline-flex items-center space-x-2 rounded-full border-b-[1px] border-l-[1px] border-r-[1px] border-t-[1px] border-[#076AFF] bg-blue-50 px-3 py-1.5 text-center font-sans   text-xs text-blue-600"
            >
                <div className=" font-sans font-medium italic tracking-tight md:tracking-wide">{text}</div>
                {arrow && (
                    <Image alt="" width={8} height={8} src="./arrowupright.svg" className="my-auto aspect-square w-2" />
                )}
            </div>
        </div>
    );
};
